.print-designer-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--control-bg);
  border-radius: var(--border-radius);
}
.print-designer-wrapper .preview-container {
  margin: 40px 10px;
}

@media only screen and (min-width: 700px) {
  .print-designer-wrapper {
    align-items: center;
  }
  .print-designer-wrapper .preview-container {
    margin: 40px;
  }
}